var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c("span", [
        !_vm.showMultiple && _vm.meetingEnabled
          ? _c(
              "span",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "inlineBtn",
                    attrs: { variant: "dark" },
                    on: { click: _vm.showJitsi },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("MEETINGS.COMPOSE")) + "\n    "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.showMultiple && _vm.meetingEnabled
          ? _c(
              "span",
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: { variant: "dark" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.$t("MEETINGS.COMPOSE")))]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1705223295
                    ),
                  },
                  [
                    _vm.jitsi_confidential_enabled
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.showJitsiConfidentially },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                src: "/img/message_type/jitsi-secure-meeting.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.$t("MESSAGES.TYPE.jitsi-secure-meeting")
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.jitsi_enabled
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.showJitsi },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                src: "/img/message_type/jitsi-meeting.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("MESSAGES.TYPE.jitsi-meeting"))
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.nuiteq_enabled
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.showNuiteq },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                src: "/img/message_type/nuiteq-meeting.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("MESSAGES.TYPE.nuiteq-meeting"))
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.teams_enabled
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.showTeams },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                src: "/img/message_type/teams-meeting.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("MESSAGES.TYPE.teams-meeting"))
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "span",
          { key: "AddMeeting_" + _vm.showIndex },
          [
            _vm.jitsi_enabled
              ? _c("JitsiMeeting", {
                  attrs: {
                    message_uuid: _vm.message_uuid,
                    show: _vm.showJitsiMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShowJitsiMeeting,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
            _vm.jitsi_confidential_enabled
              ? _c("JitsiConfidentiallyMeeting", {
                  attrs: {
                    message_uuid: _vm.message_uuid,
                    show: _vm.showJitsiConfidentiallyMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShowJitsiConfidentiallyMeeting,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
            _vm.nuiteq_enabled
              ? _c("NuiteqMeeting", {
                  attrs: {
                    message_uuid: _vm.message_uuid,
                    show: _vm.showNuiteqMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShowNuiteqMeeting,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
            _vm.teams_enabled
              ? _c("TeamsMeeting", {
                  attrs: {
                    message_uuid: _vm.message_uuid,
                    show: _vm.showTeamsMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShowTeamsMeeting,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }